(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/campaign-step/assets/javascripts/hbs-helper.js') >= 0) return;  svs.modules.push('/components/lb-ui/campaign-step/assets/javascripts/hbs-helper.js');
"use strict";


const defaultSteps = ['Bli kund', 'Sätt in', 'Spela', 'Få bonus'];
Handlebars.registerHelper('campaignStep', function (currentStep, branding) {
  let steps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultSteps;
  return svs.lb_ui.campaign_step.templates.campaign_step({
    steps: steps.map((step, i) => ({
      label: step,
      isActive: i <= currentStep
    })),
    currentStep,
    branding: branding !== null && branding !== void 0 ? branding : ''
  });
});

 })(window);